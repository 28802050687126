<template>
  <div class="w-100 my-5">
    <div v-if="drinkPayoutList">
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('payout_amount') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(payoutAmount) }}</span>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('already_paid') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(alreadyPaid) }}</span>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('pending') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(pending) }}</span>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column" v-if="eventInfo">
          <span class="fa fa-euro-sign font-15 color-red">&nbsp;&nbsp;&nbsp;{{ $t('not_paid') }}</span>
          <span class="mt-3 font-bold font-50 color-red">{{ getPriceFormat(notPaid) }}</span>
        </div>
      </div>
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white row">
          <div class="col-sm my-1 vertical-center" v-if="organizerInfo">
            <img class="circle-image-30" :src="organizerInfo.thumbnailUrl || organizerInfo.photoUrl || assets.profile" />
            <span class="ml-2 font-15 font-bold">{{ organizerInfo.userName }}</span>
          </div>
          <div class="col-sm my-1 vertical-center" v-else-if="eventInfo">
            <img :width="60" :height="40" :src="eventInfo.thumbnailUrl || eventInfo.photoUrl" />
            <span class="ml-2 font-15 font-bold">{{ eventInfo.name }}</span>
          </div>
          <DatePicker
            class="col-sm my-1"
            v-model="selectedMonth"
            type="month"
            @change="onMonthChanged"
            :placeholder="$t('select_month')"
            v-if="organizerInfo"
          />
          <DatePicker
            class="col-sm my-1"
            v-model="dateRange"
            type="date"
            range
            @change="onDateRangeChanged"
            :placeholder="$t('select_date_range')"
            v-if="organizerInfo"
          />
          <div class="col-sm my-1">
            <v-btn class="ml-auto button-normal" @click="generateOrganizerInvoice()" v-if="organizerInfo" :disabled="(!selectedMonth && !dateRange) || paidList.length === 0">
              <span>{{ $t('invoice') }}</span>
            </v-btn>
            <v-btn class="ml-auto button-normal" @click="generateEventInvoice()" v-else-if="eventInfo">
              <span>{{ $t('invoice') }}</span>
            </v-btn>
            <v-btn class="ml-auto button-normal" @click="requestDrinkPayoutTask()" v-else>
              <span>{{ $t('payout_request') }}</span>
            </v-btn>
          </div>
          <div class="col-sm my-1 vertical-center">
            <span class="font-bold font-15 alpha-07">{{ $t('withdraw') }}:</span>
            <span class="mx-5 font-bold font-20 color-blue">{{ getDecimalFormat(items.length) }}</span>
          </div>
          <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`" v-if="!organizerInfo && !eventInfo">
            <input class="w-75" v-model="keyword" />
            <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
              <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1900"
        :pdf-quality="2"
        :manual-pagination="true"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress"
        ref="html2Pdf1">
        <section slot="pdf-content">
          <div class="d-flex flex-column" v-if="eventInfo && eventOrganizerInfo && eventOrganizerInfo.organizerInfo">
            <div class="d-flex justify-content-end">
              <img :src="assets.logo" :height="30" />
            </div>
            <span class="mt-2 font-10 color-black">Eventbox / Cremon 11 / 20457 Hamburg</span>
            <span class="mt-10 font-10 color-black">{{ eventOrganizerInfo.organizerInfo.name }}</span>
            <span class="mt-2 font-10 color-black">{{ eventOrganizerInfo.organizerInfo.address }}</span>
            <span class="mt-2 font-10 color-black" v-if="eventOrganizerInfo.organizerInfo.taxCode">{{ $t('tax_code') }}: {{ eventOrganizerInfo.organizerInfo.taxCode }}</span>
            <span class="mt-2 font-10 color-black" v-if="eventOrganizerInfo.organizerInfo.ustId">UST ID: {{ eventOrganizerInfo.organizerInfo.ustId }}</span>
            <span class="mt-10 font-12 font-bold">Beschreibung</span>
            <span class="mt-2 font-10 color-black">Gutschrift für Provisionsleistungen siehe Kundennummer: {{ zeroPad(eventOrganizerInfo.customerNumber) }}</span>
            <span class="mt-2 font-10 color-black">Rechnungsnummer: {{ zeroPad(eventInfo.invoiceNumber) }}</span>
            <span class="mt-5 font-10 color-black">{{ eventInfo.name }}</span>
            <span class="mt-2 font-10 color-black">Für den Zeitraum: {{ getDateStringFromTimestamp(eventInfo.startAt, 'DD.MM.yyyy') }} - {{ getDateStringFromTimestamp(eventInfo.endAt, 'DD.MM.yyyy') }}</span>
            <div class="mt-10 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('sales_amount') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(salesAmount) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid_online') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(paidOnline) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid_by_card') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(paidCard) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid_by_cash') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(paidCash) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-3 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('tip') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(tipAmount) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid_online') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(tipOnline) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid_by_card') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(tipCard) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid_by_cash') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(tipCash) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-3 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('handling_fee') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(handlingFee) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-3 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('payout_amount') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(payoutAmount) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(alreadyPaid) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('pending') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(pending) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-2 d-flex" v-if="eventInfo">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('not_paid') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(notPaid) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <span class="mt-10 font-10 color-black">Die Abwicklungsgebühr enthält 19% Umsatzsteuer.</span>
            <span class="font bold font-12 color-black" v-if="$route.params.eventId === 'atEarJSS9jonLk4zE9Ws'">Wichtiger Hinweis:</span>
            <span class="mt-3 font-10 color-black" v-if="$route.params.eventId === 'atEarJSS9jonLk4zE9Ws'">Dieses Event wurde im Rahmen einer Kooperation durchgeführt. Der Veranstalter erhält eine Auszahlung in Höhe von 2.131,79 € auf das unten genannte Konto. Die Überweisung erfolgt in den nächsten Tagen.</span>
            <span class="my-20 mx-auto font-10 color-black">Die Gutschrift erfolgt in den nächsten Tagen auf das angegebene Konto.</span>
            <div class="mt-20 d-flex">
              <div class="col-sm-4 d-flex flex-column">
                <span class="font-8 color-black font-bold">EVENTBOX</span>
                <span class="mt-1 font-8 color-black">Mail: info@eventboxde.com</span>
              </div>
              <div class="col-sm-4 d-flex flex-column">
                <span class="font-8 color-black font-bold">Bankverbindung:</span>
                <span class="mt-1 font-8 color-black">Sparkasse Guenzburg-Krumbach</span>
                <span class="mt-1 font-8 color-black">IBAN: DE50 7205 1840 0040 7969 55</span>
                <span class="mt-1 font-8 color-black">BIC: BYLADEM1GZK</span>
              </div>
              <div class="col-sm-4 d-flex flex-column">
                <span class="font-8 color-black font-bold">Gerichtsstand</span>
                <span class="mt-1 font-8 color-black">Memmingen</span>
                <span class="mt-1 font-8 color-black">HRB 20618</span>
                <span class="mt-1 font-8 color-black">Steuernummer: 151/125/90053</span>
                <span class="mt-1 font-8 color-black">UST ID: DE361515286</span>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1900"
        :pdf-quality="2"
        :manual-pagination="true"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress"
        ref="html2Pdf2">
        <v-app class="inner-background" slot="pdf-content">
        <div class="d-flex flex-column" v-if="organizerInfo && organizerInfo.organizerInfo && paidList.length > 0 && (selectedMonth || dateRange)">
          <div class="d-flex justify-content-end">
            <img :src="assets.logo" :height="30" />
          </div>
          <span class="mt-2 font-10 color-black">Eventbox / Cremon 11 / 20457 Hamburg</span>
          <span class="mt-10 font-10 color-black">{{ organizerInfo.organizerInfo.name }}</span>
          <span class="mt-2 font-10 color-black">{{ organizerInfo.organizerInfo.address }}</span>
          <span class="mt-2 font-10 color-black" v-if="organizerInfo.organizerInfo.taxCode">{{ $t('tax_code') }}: {{ organizerInfo.organizerInfo.taxCode }}</span>
          <span class="mt-2 font-10 color-black" v-if="organizerInfo.organizerInfo.ustId">UST ID: {{ organizerInfo.organizerInfo.ustId }}</span>
          <span class="mt-10 font-12 font-bold">Beschreibung</span>
          <span class="mt-2 font-12 color-black">Datum: {{ getDateStringFromDate(new Date(), 'DD.MM.YYYY HH:mm') }}</span>
          <span class="mt-2 font-12 color-black">Kundennummer: {{ zeroPad(organizerInfo.customerNumber) }}</span>
          <span class="mt-2 font-12 color-black">Rechnungsnummer: {{ getDateStringFromDate(new Date(), 'YYYY') }}EC-{{ zeroPad(organizerInfo.customerNumber) }}-{{ monthsSinceJan2023(dateRange && dateRange.length === 2 ? dateRange[0] : selectedMonth) }}</span>
          <span class="mt-2 font-12 color-black" v-if="selectedMonth">Zeitraum: {{ getDateStringFromDate(selectedMonth, 'MMMM YYYY') }}</span>
          <span class="mt-2 font-12 color-black" v-if="dateRange && dateRange.length === 2">Zeitraum: {{ getDateStringFromDate(dateRange[0], 'DD.MM.YYYY') }} - {{ getDateStringFromDate(dateRange[1], 'DD.MM.YYYY') }}</span>
          <span class="mt-20 font-12 color-black">Die Auszahlung wurde auf Ihr Bankkonto {{ organizerInfo.organizerInfo.cardNumber.length > 2 ? organizerInfo.organizerInfo.cardNumber.substring(0, 2) : '' }}*****{{ organizerInfo.organizerInfo.cardNumber.length > 2 ? organizerInfo.organizerInfo.cardNumber.substring(organizerInfo.organizerInfo.cardNumber.length - 2) : '' }} überwiesen!</span>
          <hr class="my-15" style="height:2px; border-top:2px solid #A10559;" />
          <div class="w-100">
            <table class="w-100" border="1">
              <thead>
                <tr>
                  <th style="width: 20%;" class="p-1 font-10 color-black">Tag und Monat</th>
                  <th style="width: 23%;" class="p-1 font-10 color-black">EC-KARTENZAHLUNG</th>
                  <th style="width: 17%;" class="p-1 font-10 color-black">Trinkgeld</th>
                  <th style="width: 22%;" class="p-1 font-10 color-black">Abwicklungsgebühr</th>
                  <th style="width: 18%;" class="p-1 font-10 color-black">Auszahlung</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paidList" :key="(item, index)">
                  <td class="p-1 font-10 color-black">{{ getDateStringFromTimestamp(item.paidAt) }}</td>
                  <td class="p-1 font-10 color-black">{{ getPriceFormat(item.paidCard) }}</td>
                  <td class="p-1 font-10 color-black">{{ getPriceFormat(item.tipAmount) }}</td>
                  <td class="p-1 font-10 color-black">{{ getPriceFormat(item.handlingFee) }}</td>
                  <td class="p-1 font-10 color-black">{{ getPriceFormat(item.payoutAmount) }}</td>
                </tr>
                <tr>
                  <td class="p-1 font-10 color-black font-bold">{{ $t('total_price') }}</td>
                  <td class="p-1 font-10 color-black font-bold">{{ getPriceFormat(totalPaidCard) }}</td>
                  <td class="p-1 font-10 color-black font-bold">{{ getPriceFormat(totalTipAmount) }}</td>
                  <td class="p-1 font-10 color-black font-bold">{{ getPriceFormat(totalHandlingFee) }}</td>
                  <td class="p-1 font-10 color-black font-bold">{{ getPriceFormat(totalPayoutAmount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <span class="mt-20 font-12 color-black">Die Differenz ist das steuerfreie Trinkgeld des Betreibers.</span>
          <span class="mt-10 font-12 color-black">Die Abwicklungsgebühr enthält 19% Umsatzsteuer.</span>
          <span class="mt-30 font-12 color-black">Ihr eventbox Team</span>
          <hr class="mt-10" style="height:2px; border-top:2px solid #A10559;" />
          <span class="mt-5 font-12 color-black text-center">Eventbox UG</span>
          <span class="mt-2 font-12 color-black text-center">Geschäftsstelle: München</span>
          <span class="mt-2 font-12 color-black text-center">Steuernummer: 151/125/90053</span>
          <span class="mt-1 font-12 color-black text-center">UST ID: DE361515286</span>
          <span class="mt-2 font-12 color-black text-center">Amtsgericht München, HRB 281964</span>
        </div>
      </v-app>
      </vue-html2pdf>
      <div class="row mx-auto" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_200, items.length))" :key="(item, index)">
        <div class="col-sm m-2 p-0 box-white row vertical-center">
          <div class="col-sm d-flex flex-column">
            <div class="my-1 vertical-center" v-if="!organizerInfo">
              <img class="circle-image-30" :src="item.organizerPhotoUrl" />
              <span class="ml-2 ellipsis font-12" :title="item.organizerName">{{ item.organizerName }}</span>
            </div>
            <img width="200px" :src="item.eventPhotoUrl" />
            <span class="my-1 w-250px ellipsis font-bold font-12" :title="item.eventName">{{ item.eventName }}</span>
            <span class="mb-1 font-10 color-blue">{{ item.eventDate }}</span>
          </div>
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
          <div class="col-sm-3 d-flex flex-column">
            <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.payoutId }}</span>
            <div class="my-2 d-flex justify-content-between" v-if="item.tag">
              <span class="font-12 font-bold color-red">{{ $t('tag') }}</span>
              <span class="ml-2 font-12 font-bold color-red">{{ item.tag }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-12 font-bold">{{ $t('sales_amount') }}</span>
              <span class="ml-2 font-12 font-bold">{{ getPriceFormat(item.salesAmount) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-12 font-bold color-red">{{ $t('handling_fee') }}</span>
              <span class="ml-2 font-12 font-bold color-red">{{ getPriceFormat(item.handlingFee) }}</span>
            </div>
            <div class="mt-2 ml-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('paid_online') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getPriceFormat(item.paidOnline) }}</span>
            </div>
            <div class="mt-2 ml-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('paid_by_card') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getPriceFormat(item.paidCard) }}</span>
            </div>
            <div class="mt-2 ml-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('paid_by_cash') }}</span>
              <span class="ml-2 font-12 font-bold">{{ getPriceFormat(item.paidCash) }}</span>
            </div>
            <div class="mt-3 d-flex justify-content-between">
              <span class="font-12 font-bold color-blue">{{ $t('payout_amount') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getPriceFormat(item.payoutAmount) }}</span>
            </div>
            <div class="mt-5 d-flex justify-content-between" v-if="item.note">
              <span class="font-12 font-bold">Wichtiger Hinweis:</span>
              <span class="ml-2 font-12 font-bold">{{ item.note }}</span>
            </div>
          </div>
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
          <div class="col-sm-3 d-flex flex-column">
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-12 font-bold">{{ $t('tip') }}</span>
              <span class="ml-2 font-12 font-bold">{{ getPriceFormat(item.tipAmount) }}</span>
            </div>
            <div class="mt-2 ml-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('paid_online') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getPriceFormat(item.tipOnline) }}</span>
            </div>
            <div class="mt-2 ml-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('paid_by_card') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getPriceFormat(item.tipCard) }}</span>
            </div>
            <div class="mt-2 ml-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('paid_by_cash') }}</span>
              <span class="ml-2 font-12 font-bold">{{ getPriceFormat(item.tipCash) }}</span>
            </div>
          </div>
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
          <div class="col-sm d-flex flex-column">
            <div class="mt-2 d-flex justify-content-between vertical-center">
              <div class="d-flex flex-column" v-if="item.organizerInfo">
                <span class="mt-2 font-12">{{ item.organizerInfo.cardName }}</span>
                <span class="mt-2 font-12">{{ item.organizerInfo.cardNumber }}</span>
                <span class="mt-2 font-12">{{ item.organizerInfo.cardBIC }}</span>
                <span class="mt-2 font-12" v-if="item.organizerInfo.swiftCode">{{ item.organizerInfo.swiftCode }}</span>
              </div>
              <v-btn icon small @click="deleteDrinkPayoutInfoTask(item.payoutId, item.eventId)" v-if="hostname === 'localhost' && item.eventId.startsWith(TEST_PREFIX)">
                <i class="flaticon-delete color-red"></i>
              </v-btn>
            </div>
            <div class="mt-5 d-flex justify-content-between">
              <span class="font-12">{{ $t('requested') }}</span>
              <span class="ml-2 font-12 font-bold">{{ getDateStringFromTimestamp(item.requestedAt) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.requestedBy">
              <span class="font-12 color-red">{{ $t('requested_by') }}</span>
              <span class="ml-2 font-bold font-12 color-red">{{ item.requestedBy }}</span>
            </div>
            <div class="mt-5 d-flex justify-content-between" v-if="item.paidAt">
              <span class="font-12">{{ $t('paid') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getDateStringFromTimestamp(item.paidAt) }}</span>
            </div>
            <v-btn class="mt-5 ml-auto button-normal" small @click="setDrinkPayoutPaidTask(item.payoutId)" :disabled="item.payoutAmount <= 0" v-else>
              <span>{{ $t('payment_done') }}</span>
            </v-btn>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_200 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import VueHtml2pdf from 'vue-html2pdf';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getPriceFormat, getDecimalFormat, getDateStringFromDate, getDateStringFromTimestamp, toFileName, zeroPad, showLoading, monthsSinceJan2023 } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import logo from '@/assets/image/logo_for_invoice.png';

export default {
  name: 'DrinkPayoutList',
  components: {
    VueHtml2pdf,
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    organizerInfo() {
      if (this.$route.params.organizerId) {
        return this.organizerList.find(element => element.userId === this.$route.params.organizerId);
      } else {
        return null;
      }
    },
    eventList() {
      return this.$store.state.eventList || [];
    },
    eventInfo() {
      if (this.$route.params.eventId) {
        return this.eventList.find(element => element.eventId === this.$route.params.eventId);
      } else {
        return null;
      }
    },
    eventOrganizerInfo() {
      if (this.eventInfo) {
        return this.organizerList.find(element => element.userId === this.eventInfo.userId);
      } else {
        return null;
      }
    },
    drinkPayoutList() {
      return this.$store.state.drinkPayoutList;
    },
    params() {
      return this.$route.params;
    },
    totalPaidCard() {
      return this.paidList.reduce((total, item) => total + item.paidCard, 0);
    },
    totalTipAmount() {
      return this.paidList.reduce((total, item) => total + item.tipAmount, 0);
    },
    totalHandlingFee() {
      return this.paidList.reduce((total, item) => total + item.handlingFee, 0);
    },
    totalPayoutAmount() {
      return this.paidList.reduce((total, item) => total + item.payoutAmount, 0);
    }
  },
  watch: {
    params() {
      this.selectedMonth = null;
      this.dateRange = null;
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    eventList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    drinkPayoutList() {
      this.refreshData();
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile,
        logo
      },
      htmlToPdfOptions: {
        margin: 10,
        filename: 'Invoice',
        image: {
          type: 'jpeg',
          quality: 1.0
        },
        html2canvas: {
          scale: 5,
          useCORS: true
        }
      },
      hostname: window.location.hostname,
      selectedMonth: null,
      dateRange: null,
      salesAmount: 0,
      paidOnline: 0,
      paidCard: 0,
      paidCash: 0,
      tipAmount: 0,
      tipOnline: 0,
      tipCard: 0,
      tipCash: 0,
      handlingFee: 0,
      payoutAmount: 0,
      notPaid: 0,
      alreadyPaid: 0,
      pending: 0,
      pageIndex: 1,
      drinkOrderList: null,
      cacheEventList: [],
      items: [],
      paidList: [],
      keyword: '',
      loader: null
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getPriceFormat,
    getDecimalFormat,
    getDateStringFromDate,
    getDateStringFromTimestamp,
    monthsSinceJan2023,
    zeroPad,
    onMonthChanged(value, type) {
      this.dateRange = null;
      this.refreshData();
    },
    onDateRangeChanged(value, type) {
      if (value && value.length === 2 && value[0] && value[1]) {
        this.selectedMonth = null;
      } else {
        this.selectedMonth = new Date();
      }
      this.refreshData();
    },
    onProgress(progress) {
      if (progress === 100 && this.loader) {
        this.loader.hide();
        this.loader = null;
      }
    },
    refreshData() {
      const keyword = this.keyword.toLowerCase();
      if (this.drinkPayoutList && this.organizerList && this.eventList) {
        var payoutAmount = 0;
        var alreadyPaid = 0;
        var pending = 0;
        const items = [];
        const paidList = [];
        this.drinkPayoutList.filter(element => this.filterPayout(element)).forEach(payoutInfo => {
          const item = JSON.parse(JSON.stringify(payoutInfo));
          const organizerInfo = this.organizerList.find(element => element.userId === payoutInfo.organizerId);
          const eventInfo = this.getEventInfo(payoutInfo.eventId);
          item['organizerName'] = organizerInfo ? organizerInfo.userName : '';
          item['organizerPhotoUrl'] = organizerInfo && organizerInfo.photoUrl ? organizerInfo.photoUrl : this.assets.profile;
          item['organizerInfo'] = organizerInfo ? organizerInfo.organizerInfo : null;
          item['eventName'] = eventInfo ? eventInfo.name : '';
          item['eventDate'] = eventInfo ? `${getDateStringFromTimestamp(eventInfo.startAt)} - ${getDateStringFromTimestamp(eventInfo.endAt)}` : '';
          item['eventPhotoUrl'] = eventInfo ? eventInfo.photoUrl : '';
          if (!keyword || !eventInfo || !organizerInfo ||
            (eventInfo && eventInfo.name.toLowerCase().includes(keyword)) ||
            (organizerInfo && organizerInfo.userName.toLowerCase().includes(keyword))) {
            payoutAmount += payoutInfo.payoutAmount;
            if (payoutInfo.paidAt) {
              alreadyPaid += payoutInfo.payoutAmount;
            } else {
              pending += payoutInfo.payoutAmount;
            }
            items.push(item);
            if (item.paidAt) {
              paidList.push(item);
            }
          }
        });
        items.sort((a, b) => (a.requestedAt.seconds < b.requestedAt.seconds) ? 1 : -1);
        paidList.sort((a, b) => (a.requestedAt.seconds > b.requestedAt.seconds) ? 1 : -1);
        this.pageIndex = 1;
        this.items = items;
        this.payoutAmount = payoutAmount;
        this.paidList = paidList;
        this.alreadyPaid = alreadyPaid;
        this.pending = pending;
        this.notPaid = payoutAmount - alreadyPaid - pending;
      }
    },
    filterPayout(element) {
      if (this.$route.params.organizerId) {
        if (this.selectedMonth) {
          if (!element.createdAt && !element.requestedAt) {
            return false;
          }
          if (element.createdAt && getDateStringFromDate(this.selectedMonth, 'YYYY-MM') !== getDateStringFromTimestamp(element.createdAt, 'YYYY-MM')) {
            return false;
          }
          if (element.requestedAt && getDateStringFromDate(this.selectedMonth, 'YYYY-MM') !== getDateStringFromTimestamp(element.requestedAt, 'YYYY-MM')) {
            return false;
          }
        }
        if (this.dateRange && this.dateRange.length === 2) {
          if (!element.createdAt && !element.requestedAt) {
            return false;
          }
          if (element.createdAt) {
            if (getDateStringFromDate(this.dateRange[0], 'YYYY-MM-DD') > getDateStringFromTimestamp(element.createdAt, 'YYYY-MM-DD')) {
              return false;
            }
            if (getDateStringFromDate(this.dateRange[1], 'YYYY-MM-DD') < getDateStringFromTimestamp(element.createdAt, 'YYYY-MM-DD')) {
              return false;
            }
          }
          if (element.requestedAt) {
            if (getDateStringFromDate(this.dateRange[0], 'YYYY-MM-DD') > getDateStringFromTimestamp(element.requestedAt, 'YYYY-MM-DD')) {
              return false;
            }
            if (getDateStringFromDate(this.dateRange[1], 'YYYY-MM-DD') < getDateStringFromTimestamp(element.requestedAt, 'YYYY-MM-DD')) {
              return false;
            }
          }
        }
        return element.organizerId === this.$route.params.organizerId;
      } else if (this.$route.params.eventId) {
        return element.eventId === this.$route.params.eventId;
      } else {
        return true;
      }
    },
    getEventInfo(eventId) {
      const cacheInfo = this.cacheEventList.find(element => element.eventId === eventId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const eventInfo = this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
        if (eventInfo) {
          this.cacheEventList.push(eventInfo);
        }
        return eventInfo;
      }
    },
    generateEventInvoice() {
      if (this.eventInfo && this.eventOrganizerInfo && this.eventOrganizerInfo.organizerInfo) {
        if (this.drinkOrderList) {
          this.loader = showLoading(this);
          this.htmlToPdfOptions.filename = `${toFileName(this.eventInfo.name)}_${getDateStringFromDate()}`;
          this.$refs.html2Pdf1.generatePdf();
        } else {
          this.getDrinkOrderListTask();
        }
      }
    },
    generateOrganizerInvoice() {
      if (this.organizerInfo && this.paidList.length > 0 && (this.selectedMonth || this.dateRange)) {
        this.loader = showLoading(this);
        if (this.dateRange && this.dateRange.length == 2 && this.dateRange[0] && this.dateRange[1]) {
          this.htmlToPdfOptions.filename = `${toFileName(this.organizerInfo.userName)}_${getDateStringFromDate(this.dateRange[0], 'MMDDYYYY')}_${getDateStringFromDate(this.dateRange[1], 'MMDDYYYY')}`;
        } else {
          this.htmlToPdfOptions.filename = `${toFileName(this.organizerInfo.userName)}_${getDateStringFromDate(this.selectedMonth, 'MMMM_YYYY')}`;
        }
        this.$refs.html2Pdf2.generatePdf();
      }
    },
    getDrinkOrderListTask() {
      if (!this.$route.params.eventId) {
        return;
      }
      if (this.drinkOrderList) {
        this.generateEventInvoice();
        return;
      }
      const loader = showLoading(this, 1);
      const q = query(collection(firestore, 'drinkOrder'), where('eventId', '==', this.$route.params.eventId), where('paymentMethod', '!=', ''));
      getDocs(q).then(querySnapshot => {
        loader.hide();
        var salesAmount = 0;
        var paidOnline = 0;
        var paidCard = 0;
        var paidCash = 0;
        var tipAmount = 0;
        var tipOnline = 0;
        var tipCard = 0;
        var tipCash = 0;
        var handlingFee = 0;
        const list = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          salesAmount += data.drinkPrice;
          tipAmount += data.tipPrice;
          handlingFee += data.handlingFee;
          if (data.paymentMethod === this.PAYMENT_TYPE_CARD) {
            paidCard += data.drinkPrice;
            tipCard += data.tipPrice;
          } else if (data.paymentMethod === this.PAYMENT_TYPE_CASH) {
            paidCash += data.drinkPrice;
            tipCash += data.tipPrice;
          } else if (data.referenceId) {
            paidOnline += data.drinkPrice;
            tipOnline += data.tipPrice;
          }
        });
        this.drinkOrderList = list;
        this.salesAmount = salesAmount;
        this.paidOnline = paidOnline;
        this.paidCard = paidCard;
        this.paidCash = paidCash;
        this.tipAmount = tipAmount;
        this.tipOnline = tipOnline;
        this.tipCard = tipCard;
        this.tipCash = tipCash;
        this.handlingFee = handlingFee;
        this.payoutAmount = salesAmount - paidCash - handlingFee + tipOnline + tipCard;
        this.notPaid = this.payoutAmount - this.alreadyPaid - this.pending;
        this.generateEventInvoice();
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    },
    requestDrinkPayoutTask() {
      if (!confirm(this.$t('confirm_request_drink_payout'))) {
        return;
      }
      const params = {
        functionName: 'requestDrinkPayoutForPastEvents'
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    setDrinkPayoutPaidTask(payoutId) {
      if (!payoutId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm(this.$t('confirm_withdraw_done'))) {
        return;
      }
      const params = {
        functionName: 'setDrinkPayoutPaid',
        payoutId: payoutId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    deleteDrinkPayoutInfoTask(payoutId, eventId) {
      if (!payoutId || !eventId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      const params = {
        functionName: 'deleteDrinkPayoutInfo',
        payoutId: payoutId,
        eventId: eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>